import React from "react";
import ComponentIndex from "../navbar";
import FooterIndex from "../footer/index"
import "./App.css"

function Services() {
 
  return (
    <>
    <ComponentIndex/>
        <div className="serviceContent"> 
    <b><h1 class="h1" className="text-primary">NGS and Bioinformatics Analysis Services</h1></b>
      <h2 class="h2" className="text-primary">A. Genome Informatics Offerings</h2>
      <h3 class="h3">
        1. Pre-project consultation for design of experiments and analysis strategy
      </h3>
      <h3 class="h3"> 2. Whole genome sequencing assembly and annotation</h3> 
      <h3 class="h3">3. Genomeresequencing analysis <space>(Reference based)</space></h3>
      <h3 class="h3">4. Exome analysis</h3>
      <h3 class="h3">5. Amplicon analysis (16S rRNA/ITS) </h3>
      <h3 class="h3">6. Metagenome analysis </h3>
      <h3 class="h3">7. Transposon analysis </h3>
      <h3 class="h3">8. Phylogeny analysis </h3>
      <h3 class="h3">9. Variant/SNP calling </h3>
      <h3 class="h3">10. SSR Marker analysis</h3>
      <h3 class="h3">11. Genotyping studies and analysis</h3>
     <h2 class="h2" className="text-primary">B. Transcriptome Analysis</h2>
      <h3 class="h3">1. De novo Transcriptome assembly and annotation </h3>
      <h3 class="h3">2. Reference based Transcriptome Analysis</h3>
      <h3 class="h3">3. Metatranscriptome Analysis</h3>
      <h3 class="h3">4. Small RNA/miRNA sequence assembly and analysis</h3>
      <h3 class="h3">5. Differential Gene expression analysis from transcriptome studies</h3>
      <h2 class="h2" className="text-primary">C. Hybrid Assembly and Analysis</h2>
      <h3 class="h3">
        1. Hybrid data assembly (To validate the NGS data & closing gaps from
        <br></br>
        different platforms - Illumina, Nanopore, MGI, Ion Torrent, Pacbio)
      </h3>
     <h2 class="h2" className="text-primary">D.Other Bioinformatic Analysis</h2>
      <h3 class="h3">1. Sanger sequencing analysis</h3>
      <h3 class="h3">2. Comparative genome studies</h3>
      <h3 class="h3">
        3. Identification and gene annotation of pathway and gene ontology
      </h3>
      <h3 class="h3">4. Gene predictions using statistical models</h3>
      <h3 class="h3">5.Identification of novel SNPs/InDels </h3>
      <h2 class="h2" className="text-primary">E. Artificial Intelligence and Data Science</h2>
      <h3 class="h3">
        1. Custom based services using AI and data science in the projects
      </h3>
    </div>
    <FooterIndex/>
  </>
  
    
  );
}
export default Services;