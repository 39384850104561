import React from 'react';
import im1 from '../../images/Asset 4.svg';
import { Link } from 'react-router-dom';

function ComponentIndex() {

  // const navigate=useNavigate()import Nandan from "../../images/nandan.jpg";
    return (
      <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar-logo-left w-nav">
    <div className="navbarcontainer w-container">
        <div className="navbar-content">
          <div className="navbar-brand">
            <a href="/" aria-current="page" className="w-inline-block w--current"
              ><img
                  src={im1}
                  style={{ padding: "20px" }}
                loading="lazy"
                width="212"
                alt="" /></a>
          </div>
          <nav role="navigation" className="navbar-menu w-nav-menu">
         
              <Link to={"/services"} className="button-2 w-button px-2  text text-decoration-none ">Services</Link>              
              <Link to={"/carrers"} className="button-2 w-button px-2  text text-decoration-none">Careers</Link>
              <Link to={"/contactus" } className="button-2 w-button px-2 text text-decoration-none ">Contact Us</Link>
            
          </nav>
        </div>
        <div className="menu-button w-nav-button">
          <div className="icon-3 w-icon-nav-menu"></div>
        </div>
      </div>
    </div>  
  )
}

export default ComponentIndex