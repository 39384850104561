import './App.css';
import CareerIndex from './components/Careers';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainIndex from './Main';
import ServiceIndex from './components/ServiceIndex/App';
import ContactUsIndex from './components/ContactUs';
import DisplayIndex from './components/Careers/Display';



function App() {
  return (
    <>
      <BrowserRouter>
      
        <Routes>
          <Route path="/" element={ <MainIndex/>}/>
          <Route path="/services" element={<ServiceIndex/> }></Route>
          <Route path="/carrers" element={ <CareerIndex/>}/>
          <Route path="/contactus" element={<ContactUsIndex />} />
          <Route path="/careers/:paraid" element={<DisplayIndex />} />

        </Routes>
      </BrowserRouter>
      
      
   
    {/*  <div className="body-3">
     
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar-logo-left w-nav"
    >
      <div className="navbarcontainer w-container">
        <div className="navbar-content">
          <div className="navbar-brand">
            <a href="/" aria-current="page" className="w-inline-block w--current"
              ><img
                src="./images/Asset 4.svg"
                  style={{ padding: "20px" }}
                loading="lazy"
                width="212"
                alt="" /></a>
          </div>
          <nav role="navigation" className="navbar-menu w-nav-menu">
            <a href="/news-and-blog" className="navbar-link w-nav-link"
              ><div className="text">Blog</div></a
            ><a href="/demo" className="button-blue-border w-button">Demo</a>
          </nav>
        </div>
        <div className="menu-button w-nav-button">
          <div className="icon-3 w-icon-nav-menu"></div>
        </div>
      </div>
    </div>
    <div className="hero-heading-left">
      <div className="container">
        <div className="column padded">
          <div className="column">
            <p className="text-2"><br /><br />‍</p>
            <div className="title-copy-goes-here-be-awesome">
              AI-in-the-Loop to accelerate your Bio-informatics
            </div>
          </div>
          <a href="/demo" className="button-blue-border bigbutton w-button"
            >Request a Demo</a
          >
        </div>
        <img
          src="./images/Asset 25.svg"
          loading="lazy"
          width="500"
          height="500"
          alt=""
          className="vectors-wrapper-2"
        />
      </div>
    </div>
    <div className="testimonial-image-left">
      <div className="small-columns">
        <div className="column-2">
          <div className="image-wrapper">
            <img
              src="https://assets-global.website-files.com/6488527e92750615c6e89349/64885c29ecf9e474482da14b_pexels-tara-winstead-7723327-1.png"
              loading="lazy"
              width="328"
              height="490"
              alt=""
              srcset="
                https://assets-global.website-files.com/6488527e92750615c6e89349/64885c29ecf9e474482da14b_pexels-tara-winstead-7723327-1-p-500.png 500w,
                https://assets-global.website-files.com/6488527e92750615c6e89349/64885c29ecf9e474482da14b_pexels-tara-winstead-7723327-1.png       656w
              "
              sizes="(max-width: 479px) 83vw, 320px"
              className="pexels-tara-winstead-7723327-1"
            />
          </div>
        </div>
        <div className="content">
          <div className="error-7f33e22a-eb78-de74-e04c-c2e30371c89e">
            Data + AI workflows for your Genetic Analysis, with real utility.<br /><br />In
            a software environment that enables collaborative working between
            Wet and Dry lab researchers.<br /><br />Speeding up time to insight
            by up to 10X.<br />
          </div>
          <img
            src="https://assets-global.website-files.com/6488527e92750615c6e89349/64885c2bd1142053aaad9e5a_Vectors-Wrapper.svg"
            loading="lazy"
            width="441.89453125"
            height="99.63462829589844"
            alt=""
            className="vectors-wrapper-3"
          />
        </div>
      </div>
    </div>
    <section className="section">
      <div className="lefttextcontainer w-container">
        <p className="paragraph">
          The freedom to build and refine your own knowledge graphs and conduct
          context rich analyses and gene rankings. Driving confidence in drug
          targets and taking the headache out of data stewardship.<br />
        </p>
      </div>
    </section>
    <section className="section">
      <div className="lefttextcontainer w-container">
        <div className="w-embed w-iframe w-script">
            <div style={{ padding: '56.25 % 0 0 0', position: 'relative' }}>
            <iframe
                src="images/biox.mp4"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  
                }}
                title= "bioXverse_-_brand_launch (1080p)"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </section>
    <section>
      <div className="testimonial-column-light">
        <div
          className="w-layout-blockcontainer small-heading-container-smaller w-container"
        >
          <div className="small-heading-max-width">
            What can the BioXverse Environment do?
          </div>
        </div>
        <div className="columns">
          <div className="w-layout-blockcontainer container-13 w-container">
            <div className="content-12">
              <img
                src="https://assets-global.website-files.com/6488527e92750615c6e89349/648889e6b2e28e252683628e_Vectors-Wrapper.svg"
                loading="lazy"
                width="82.84844970703125"
                height="82.84845733642578"
                alt=""
                className="vectors-wrapper-24"
              />
              <div className="target-identification-and-validation-2">
                Bring Clarity from Noise
              </div>
              <div className="text-5">
                Rigorous comp bio, on an intuitive visual interface, for target
                identification and biological mechanism understanding
              </div>
            </div>
          </div>
          <div className="w-layout-blockcontainer container-14 w-container">
            <div className="content-12">
              <img
                src="https://assets-global.website-files.com/6488527e92750615c6e89349/64888baccfe322f2a62ce595_Vectors-Wrapper.svg"
                loading="lazy"
                width="82.85247802734375"
                height="82.85247802734375"
                alt=""
                className="vectors-wrapper-25"
              />
              <div className="target-identification-and-validation-2">
                Execute Powerful and Hollistic AI
              </div>
              <div className="text-5">
                Knowledge Graph generated Feature Stores for custom Gene
                Recommendation Systems
              </div>
            </div>
          </div>
          <div className="w-layout-blockcontainer container-15 w-container">
            <div className="content-12">
              <img
                src="https://assets-global.website-files.com/6488527e92750615c6e89349/64888ff09c22ce6ad0869f9c_Vectors-Wrapper.svg"
                loading="lazy"
                width="82.8499984741211"
                height="82.8499984741211"
                alt=""
                className="vectors-wrapper-26"
              />
              <div className="target-identification-and-validation-2">
                Data and IP Guardianship
              </div>
              <div className="text-5">
                What decisions and why?<br />Your data and analysis record for
                drug target patent application and regulatory submissions
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="team-circles">
        <div className="container-5">
          <div className="small-heading-container">
            <div className="small-heading">Meet the Team</div>
          </div>
          <div className="columns-3">
            <div className="teamcard">
              <div className="image-wrapper-3">
                <a
                  href=""
                  target="_blank"
                  className="w-inline-block"
                  ><img
                    src="./images/pruthvi.jpg"
                    loading="lazy"
                    width="350"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 42vw, (max-width: 1279px) 27vw, 350px"
                    alt=""
                    srcset=""
                    style={{bordeRadius: '50%',objectFit: "cover"}}
                    
                /></a>
              </div>
              <div className="content-15">
                <div className="info">
                  <div className="text-10">Co-Founder</div>
                  <div className="text-11">Dr.Pruthvi Chakravarthi</div>
                  <div className="div-block-2"></div>
                </div>
                <wrapper className="vectors-wrapper-28"></wrapper>
              </div>
            </div>
            <div className="teamcard">
              <div className="image-wrapper-3">
                <a
                  href=""
                  target="_blank"
                  className="w-inline-block"
                  ><img
                    src="./images/asha.jpeg"
                    loading="lazy"
                    width="350"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 42vw, (max-width: 1279px) 27vw, 350px"
                    alt=""
                    srcset=""
                    style={{borderRadius: '50%', objectFit: 'cover'}}
                /></a>
              </div>
              <div className="content-15">
                <div className="info">
                  <div className="text-10">Co-Founder</div>
                  <div className="text-11">Asha latha</div>
                  <div className="div-block-2"></div>
                </div>
                <wrapper className="vectors-wrapper-28"></wrapper>
              </div>
            </div>
            <div className="teamcard">
              <div className="image-wrapper-3">
                <a
                  href=""
                  target="_blank"
                  className="w-inline-block"
                  ><img
                      src="./images/nandan.jpg"
                      loading="lazy"
                      width="350"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 42vw, (max-width: 1279px) 27vw, 350px"
                      alt=""
                      srcset=""
                      style= {{bordeRadius: '50%', objectFit: 'cover'}}
                    
                /></a>
              </div>
              <div className="content-15">
                <div className="info">
                  <div className="text-10">Co-Founder</div>
                  <div className="text-11">Nandan Andra</div>
                  <div className="div-block-2"></div>
                </div>
                <wrapper className="vectors-wrapper-28"></wrapper>
              </div>
            </div>
          </div>
        </div>
        <div className="columns-3">
        
          
        </div>
      </div>
    </section>
    <section>
      <div className="features-metrics">
        <div
          className="w-layout-blockcontainer small-heading-container w-container"
        />
        
      </div>
    </section>
    <div className="hero-heading-right">
      <div className="container-7">
        <div className="column-13">
          <div className="image-wrapper-5">
            <img
              src="https://assets-global.website-files.com/6488527e92750615c6e89349/64899e4a319ce1c07ace55f2_Vectors-Wrapper.svg"
              loading="lazy"
              width="301.5492858886719"
              height="691"
              alt=""
              className="vectors-wrapper-30"
            />
          </div>
        </div>
        <div className="column-14">
          <div className="content-17">
            <div className="text-14">Request a demo or get in contact here</div>
          </div>
          <div className="form-block w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              data-wf-page-id="6488527e92750615c6e8934e"
              data-wf-element-id="2c3a14d1-e4ec-2b83-ad50-f1e98bb3a7e4"
            >
              <div className="grid-2-x-2">
                <div className="_2-columns">
                  <div className="container-10 w-container">
                    <label for="Name-4" className="field-label-2">Name</label
                    ><input
                      type="text"
                      className="text-field-2 w-input"
                      maxlength="256"
                      name="Name"
                      data-name="Name"
                      placeholder="NAME"
                      id="Name-4"
                      required=""
                    />
                  </div>
                  <div className="container-11 w-container">
                    <label for="Email-Address" className="field-label-3"
                      >Email Address</label
                    ><input
                      type="text"
                      className="text-field-2 w-input"
                      maxlength="256"
                      name="Email-Address"
                      data-name="Email Address"
                      placeholder="EMAIL ADDRESS"
                      id="Email-Address"
                      required=""
                    />
                  </div>
                </div>
                <div className="_2-columns">
                  <div className="container-8 w-container">
                    <label for="Organisation" className="field-label-5">Organisation</label>
                    <input
                      type="text"
                      className="text-field-2 w-input"
                      maxlength="256"
                      name="Organisation"
                      data-name="Organisation"
                      placeholder="ORGANISATION"
                      id="Organisation"
                      required=""
                    />
                  </div>
                  <div className="container-9 w-container">
                    <label for="Demo-or-Other" className="field-label-4">
                      What Are You Interested In?</label><input
                      type="text"
                      className="text-field-2 w-input"
                      maxlength="256"
                      name="Demo-or-Other"
                      data-name="Demo or Other"
                      placeholder="DEMO OR OTHER"
                      id="Demo-or-Other"
                    />
                  </div>
                </div>
              </div>
              <label for="field" className="field-label">Tell Us More</label>
              <textarea
                placeholder="GIVE US SOME COLOUR"
                maxlength="5000"
                id="field"
                name="field"
                data-name="Field"
                className="bigtextfield w-input"
              ></textarea>
              <div className="alignright w-container">
                <input
                  type="submit"
                  value="Submit"
                  data-wait="Please wait..."
                  className="submit-button mantlebutton w-button"
                />
              </div>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer">
      <div className="columns-5">
        <div className="column-17">
          <img
            src="./images/Asset 4.svg"
            loading="lazy"
            width="212"
            height="100"
            style={{padding:'10px'}}
            alt=""
          />
        </div>
        <div className="w-layout-blockcontainer container-18 w-container"></div>
        <div className="small-columns-2 hidden">
          <div className="column-18">
            <a href="/news-and-blog" className="button-2 w-button">Blog</a>
          </div>
          <div className="column-18"></div>
          <a href="/demo" className="button w-button">Demo</a>
        </div>
        <div className="w-layout-blockcontainer container-19 w-container"></div>
        <div className="w-layout-blockcontainer container-20 w-container">
          <div className="text-block-15">
            Copyright 2023, BioXverse Ltd.<br />All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
    
              </div>*/}
       </>
    
  );
}

export default App;

