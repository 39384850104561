import React from "react";
import "./App.css";
import Server from "./Service";
function ServiceIndex() {
 

  return (
    <div class="method">
      <Server />
    </div>
  
  );
}
export default ServiceIndex;
