import React from 'react'
import { CarrerCardData } from './data';
import { useParams } from 'react-router-dom';
import "./Display.css"
import ComponentIndex from '../navbar';
import FooterIndex from '../footer';

function DisplayIndex() {

const {paraid}=useParams()
const data=CarrerCardData.find(({id}) => {
    return parseInt(paraid) === id;
})
console.log(data)

  return (
    <>
    <ComponentIndex/>
    <div className='main'>
        <div className="container  pt-5">
    
    <div className="row 333 222"> 
        <div  key={data.id} className=''>
         <div className="card ">
           <div className="card-body">
             <h5 className="card-title mb-3 fs-2 text-primary fw-bold  ">{data.title}</h5>
             <p className='fs-5 text-black '><span className='text-black fw-semibold'> Requirements: </span>{data.req
             }</p>
              <p className='fs-5 text-black '><span className='text-black fw-semibold   '> Education: </span>{data.edu}</p>
              <h6 className='fs-5 text-black lh-lg'><span className='text-black fw-semibold  '> Description: </span>{data.desc} </h6>
             <div className='d-flex justify-content-center '>
             <a  href={`mailto:services@bioxverse.com?subject=Applying for ${data.title}&body=I am interested in applying for the ${data.title} position. Please provide me with further details.`} className="btn btn-danger btn-lg text-center">Apply Now</a>
             </div>
           </div>
         </div>
        
         </div>

      
    </div>
  </div>
    </div>
    <FooterIndex/>
  </>
  )
  
}

export default DisplayIndex