export const CarrerCardData=[
    {
        id:1,
        title:"Bioinformatician",
        req:" 0-1 Year Experience or Fresher",
        edu: "MSC in Bioinformatics",
        desc : "Understand well & analyze new business requirements and determine appropriate technical solutions keeping mind the latest technology trends in mind"
    },
    {
        id:2,
        title:"Frontend Development",
        req:" 0-1 Year Experience or Fresher",
        edu: "BSC/ MCA/ BCA",
        desc : "Fullstack frontend development with MERN Stack"
        
    },
    {
        id:3,
        title:"Backend Development",
        req:" 0-1 Year Experience or Fresher",
        edu: "BCA/ MCA",
        desc : "Middleware and Backend Development including scheduling of jobs using tools like snakemake in python"
        
    },
    // {
    //     id:4,
    //     title:"Bioinformatician",
    //     req:" 0-1 Year Experience or Fresher",
    //     edu: "BSC in computer science",
    //     desc : "Understand well & analyze new business requirements and determine appropriate technical solutions keeping mind the latest technology trends in mind"
        
    // },
    // {
    //     id:5,
    //     title:"Bioinformatician",
    //     req:" 0-1 Year Experience or Fresher",
    //     edu: "MSC in Bioinformatics",
    //     desc : "Understand well & analyze new business requirements and determine appropriate technical solutions keeping mind the latest technology trends in mind"
        
    // },
    // {
    //     id:6,
    //     title:"Bioinformatician",
    //     req:" 0-1 Year Experience or Fresher",
    //     edu: "MSC in Bioinformatics",
    //     desc : "Understand well & analyze new business requirements and determine appropriate technical solutions keeping mind the latest technology trends in mind"
        
    // },
    // {
    //     id:7,
    //     title:"Frontend Development",
    //     req:" 0-1 Year Experience or Fresher",
    //     edu: "BSC/ MCA/ BCA",
    //     desc : "Understand well & analyze new business requirements and determine appropriate technical solutions keeping mind the latest technology trends in mind"
        
    // },
    // {
    //     id:8,
    //     title:"Backend Development",
    //     req:" 0-1 Year Experience or Fresher",
    //     edu: "BCA/ MCA",
    //     desc : "Understand well & analyze new business requirements and determine appropriate technical solutions keeping mind the latest technology trends in mind"
        
    // },
    // {
    //     id:9,
    //     title:"Bioinformatician",
    //     req:" 0-1 Year Experience or Fresher",
    //     edu: "BSC in computer science",
    //     desc : "Understand well & analyze new business requirements and determine appropriate technical solutions keeping mind the latest technology trends in mind"
        
    // },
]