import React from "react"
import Nandan from "../../images/nandan.jpg";
import Pruthvi from "../../images/pruthvi.jpg";
import Asha from "../../images/asha.jpeg";

function TeamCircles() {
  return (
    <section>
      <div class="team-circles">
        <div class="container-5">
          <div class="small-heading-container">
            <div class="small-heading">Meet the Team</div>
          </div>
          <div class="columns-2">
            <div class="teamcard">
              <div class="image-wrapper-3">
                <a href="" target="_blank" class="w-inline-block">
                  <img
                    src={Pruthvi}
                    loading="lazy"
                    width="350"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 42vw, (max-width: 1279px) 27vw, 350px"
                    alt=""
                    srcset=""
                    style={{
                      borderRadius: "50%",
                      objectFit: "cover"
                    }}
                  />
                </a>
              </div>
              <div class="content-15">
                <div class="info">
                  <div class="text-10">Co-Founder</div>
                  <div class="text-11">Dr.Pruthvi Chakravarthi</div>
                  <div class="div-block-2"></div>
                </div>
                <wrapper class="vectors-wrapper-28"></wrapper>
              </div>
            </div>
            {/* <div class="teamcard">
              <div class="image-wrapper-3">
                              <a href="" target="_blank" class="w-inline-block">
                                  <img
                                      src={Asha}
                                      loading="lazy"
                                      width="350"
                                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 42vw, (max-width: 1279px) 27vw, 350px"
                                      alt=""
                                      srcset=""
                                      style={{
                                          borderRadius: "50%",
                                          objectFit: "cover"
                                      }}
                                  />
                              </a>
              </div>
              <div class="content-15">
                <div class="info">
                  <div class="text-10">Co-Founder</div>
                  <div class="text-11">Asha latha</div>
                  <div class="div-block-2"></div>
                </div>
                <wrapper class="vectors-wrapper-28"></wrapper>
              </div>
            </div> */}
            <div class="teamcard">
              <div class="image-wrapper-3">
                              <a href="" target="_blank" class="w-inline-block">
                                  <img
                                      src={Nandan}
                                      loading="lazy"
                                      width="350"
                                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 42vw, (max-width: 1279px) 27vw, 350px"
                                      alt=""
                                      srcset=""
                                      style={{
                                          borderRadius: "50%",
                                          objectFit: "cover"
                                      }}
                                  />
                              </a>
              </div>
              <div class="content-15">
                <div class="info">
                  <div class="text-10">Co-Founder</div>
                  <div class="text-11">Nandan Andra</div>
                  <div class="div-block-2"></div>
                </div>
                <wrapper class="vectors-wrapper-28"></wrapper>
              </div>
            </div>
          </div>
        </div>
        <div class="columns-3">
        
          
        </div>
      </div>
    </section>
  )
}

export default TeamCircles