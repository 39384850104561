import React from 'react'

function Testimonial() {
  return (
    <div className="testimonial-image-left">
      <div className="small-columns">
        <div className="column-2">
          <div className="image-wrapper">
            <img
              src="https://assets-global.website-files.com/6488527e92750615c6e89349/64885c29ecf9e474482da14b_pexels-tara-winstead-7723327-1.png"
              loading="lazy"
              width="328"
              height="490"
              alt=""
              srcset="
                https://assets-global.website-files.com/6488527e92750615c6e89349/64885c29ecf9e474482da14b_pexels-tara-winstead-7723327-1-p-500.png 500w,
                https://assets-global.website-files.com/6488527e92750615c6e89349/64885c29ecf9e474482da14b_pexels-tara-winstead-7723327-1.png       656w
              "
              sizes="(max-width: 479px) 83vw, 320px"
              className="pexels-tara-winstead-7723327-1"
            />
          </div>
        </div>
        <div className="content">
          <div className="error-7f33e22a-eb78-de74-e04c-c2e30371c89e">
            Data + AI workflows for your Genetic Analysis, with real utility.<br /><br />In
            a software environment that enables collaborative working between
            Wet and Dry lab researchers.<br /><br />Speeding up time to insight
            by up to 10X.<br />
          </div>
          <img
            src="https://assets-global.website-files.com/6488527e92750615c6e89349/64885c2bd1142053aaad9e5a_Vectors-Wrapper.svg"
            loading="lazy"
            width="441.89453125"
            height="99.63462829589844"
            alt=""
            className="vectors-wrapper-3"
          />
        </div>
      </div>
    </div>
  )
}

export default Testimonial