import React from 'react'
import imgf from '../../images/Asset 4.svg'
import {Link} from 'react-router-dom'

function FooterIndex() {
  return (
    <div className="footer">
      <div className="columns-5">
        <div className="column-17">
          <img
            src={imgf}
            loading="lazy"
            width="212"
            height="100"
            style={{padding:'10px'}}
            alt=""
          />
        </div>
        <div className="w-layout-blockcontainer container-18 w-container"></div>
        <div className="small-columns-2 hidden"> 
           <div className="column-18">
            {/* <a href="/news-and-blog" className="button-2 w-button px-3">Services</a> */}
            <Link to={"/services"} className="button-2 w-button px-3 text text-decoration-none ">Services</Link>
             <style>padding:'5px'</style> 
           </div>
          <div className='column-18'>
            <Link to={"/carrers"} className="button-2 w-button px-3 text text-decoration-none">Careers</Link>
            {/* <a href="" className='button-2 w-button px-3'>Careers</a> */}
            {/* <style>padding:'5px'</style> */}
           </div>
          <div className=""></div>
          {/* <a href="/demo" className="button w-button ">Contact</a> */}
          <Link to={"/contactus" } className="button-2 w-button px-3 text text-decoration-none ">ContactUs</Link>
        </div>  
        {/* <nav role="navigation" className="navbar-menu w-nav-menu">
         
              <Link to={"/services"} className="text text-decoration-none ">Services</Link>              
              <Link to={"/carrers"} className="text text-decoration-none">Careers</Link>
              <Link to={"/contactus" } className="text text-decoration-none ">Contact Us</Link>
            
          </nav> */}
        <div className="w-layout-blockcontainer container-19 w-container"></div>
        <div className="w-layout-blockcontainer container-20 w-container">
          <div className="text-block-15">
            Copyright 2023, BioXverse Ltd.<br />All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterIndex