import React, { useState } from 'react';
import { CarrerCardData } from './data';
import "./index.css"
import { useNavigate } from 'react-router-dom';
import ComponentIndex from '../navbar';
import FooterIndex from '../footer';

function App() {
    const [field, setfield] = useState(null)
 const updateftn=(paraid)=>{
setfield(paraid===field ?null:paraid)
navigate(`/careers/${paraid}`)

 }
 const navigate=useNavigate()

  return (
    <>
<ComponentIndex />
	<div className='main w-100 h-100  '>
    <div className="container pt-5 careerBody">
    
          <div className="row">
            {CarrerCardData.map(({ id, title,desc }, index) => {
              return (
               <div  key={id} className='mb-5 col-md-4 col-sm-6'>
			   <div className="card ">
				 <div className="card-body">
				   <h5 className="mb-3 card-title fs-4 fw-medium  text-center ">{title}</h5>
           <h6 className='fs-6 text-black-50 text-center mb-3'>{ desc ? desc.slice(0,75)+"...": ""}</h6>
				   <button className='btn btn-info d-flex align-items-center justify-content-center mx-auto' onClick={()=>{
					updateftn(id)
				   }}>View More</button>
				 </div>
			   </div>

		
			   </div>
              );
            })}
          </div>
        </div>
        <FooterIndex />
    </div>
   
   
   </>
  );
}

export default App;
