import React from "react";

function MyComponent() {
  return (
    <div>
      <section className="section">
        <div className="lefttextcontainer w-container">
          <p className="paragraph">
            The freedom to build and refine your own knowledge graphs and
            conduct context rich analyses and gene rankings. Driving confidence
            in drug targets and taking the headache out of data stewardship.
            <br />
          </p>
        </div>
      </section>
    </div>
  );
}

export default MyComponent;
