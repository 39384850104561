import React from 'react'

function Index() {
  return (
    <div class="hero-heading-right">
      <div class="container-7">
        <div class="column-13">
          <div class="image-wrapper-5">
                <img
                    src="https://assets-global.website-files.com/6488527e92750615c6e89349/64899e4a319ce1c07ace55f2_Vectors-Wrapper.svg" loading="lazy" width="301.5492858886719" height="691" alt="" class="vectors-wrapper-30"
                />
          </div>
        </div>
        <div class="column-14">
          <div class="content-17">
            <div class="text-14">Request a demo or get in contact <a href="mailto:">here</a></div>
          </div>
          {/* <div class="form-block w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="6488527e92750615c6e8934e" data-wf-element-id="2c3a14d1-e4ec-2b83-ad50-f1e98bb3a7e4" aria-label="Email Form">
              <div class="grid-2-x-2">
                <div class="_2-columns">
                  <div class="container-10 w-container">
                                      <label for="Name-4" class="field-label-2">Name</label>
                                      <input
                                          type="text"
                                          class="text-field-2 w-input"
                                          maxlength="256"
                                          name="Name"
                                          data-name="Name"
                                          placeholder="NAME"
                                          id="Name-4"
                                          required=""
                                      />
                  </div>
                  <div class="container-11 w-container">
                                      <label for="Email-Address" class="field-label-3">Email Address</label>
                                      <input type="text"
                                          class="text-field-2 w-input"
                                          maxlength="256"
                                          name="Email-Address"
                                          data-name="Email Address"
                                          placeholder="EMAIL ADDRESS"
                                          id="Email-Address"
                                          required=""
                                      />
                  </div>
                </div>
                <div class="_2-columns">
                  <div class="container-8 w-container">
                                      <label for="Organisation" class="field-label-5">Organisation</label>
                                      <input
                                          type="text"
                                          class="text-field-2 w-input"
                                          maxlength="256"
                                          name="Organisation"
                                          data-name="Organisation"
                                          placeholder="ORGANISATION"
                                          id="Organisation"
                                          required=""
                                      />
                  </div>
                  <div class="container-9 w-container">
                                      <label for="Demo-or-Other" class="field-label-4">What Are You Interested In?</label>
                                      <input
                                          type="text"
                                          class="text-field-2 w-input"
                                          maxlength="256"
                                          name="Demo-or-Other"
                                          data-name="Demo or Other"
                                          placeholder="DEMO OR OTHER"
                                          id="Demo-or-Other"
                                      />
                  </div>
                </div>
              </div>
              <label for="field" class="field-label">Tell Us More</label><textarea placeholder="GIVE US SOME COLOUR" maxlength="5000" id="field" name="field" data-name="Field" class="bigtextfield w-input"></textarea>
              <div class="alignright w-container">
                              <input
                                  type="submit"
                                  value="Submit"
                                  data-wait="Please wait..."
                                  class="submit-button mantlebutton w-button"
                              />
              </div>
            </form>
            <div class="w-form-done" tabindex="-1" role="region" aria-label="Email Form success">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail" tabindex="-1" role="region" aria-label="Email Form failure">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Index