import React from 'react';

function TestimonialSection() {
  return (
    <section>
      <div className="testimonial-column-light">
        <div className="w-layout-blockcontainer small-heading-container-smaller w-container">
          <div className="small-heading-max-width">
            What can the BioXverse Environment do?
          </div>
        </div>
        <div className="columns">
          <TestimonialItem
            imgSrc="https://assets-global.website-files.com/6488527e92750615c6e89349/648889e6b2e28e252683628e_Vectors-Wrapper.svg"
            imgAlt="Vector Image 1"
            heading="Bring Clarity from Noise"
            description="Rigorous comp bio, on an intuitive visual interface, for target identification and biological mechanism understanding"
          />
          <TestimonialItem
            imgSrc="https://assets-global.website-files.com/6488527e92750615c6e89349/64888baccfe322f2a62ce595_Vectors-Wrapper.svg"
            imgAlt="Vector Image 2"
            heading="Execute Powerful and Holistic AI"
            description="Knowledge Graph generated Feature Stores for custom Gene Recommendation Systems"
          />
          <TestimonialItem
            imgSrc="https://assets-global.website-files.com/6488527e92750615c6e89349/64888ff09c22ce6ad0869f9c_Vectors-Wrapper.svg"
            imgAlt="Vector Image 3"
            heading="Data and IP Guardianship"
            description="What decisions and why? Your data and analysis record for drug target patent application and regulatory submissions"
          />
        </div>
      </div>
    </section>
  );
}

function TestimonialItem({ imgSrc, imgAlt, heading, description }) {
  return (
    <div className="w-layout-blockcontainer container-13 w-container">
      <div className="content-12">
        <img
          src={imgSrc}
          loading="lazy"
          width="82.84844970703125"
          height="82.84845733642578"
          alt={imgAlt}
          className="vectors-wrapper-24"
        />
        <div className="target-identification-and-validation-2">{heading}</div>
        <div className="text-5">{description}</div>
      </div>
    </div>
  );
}

export default TestimonialSection;