import React from 'react'
import img2 from '../../images/Asset 25.svg'
function HeroHeader() {
  return (
     <div className="hero-heading-left">
      <div className="container">
        <div className="column padded">
          <div className="column">
            <p className="text-2"><br /><br />‍</p>
            <div className="title-copy-goes-here-be-awesome fs-1 fw-normal ">
              AI-in-the-Loop to accelerate your Bio-informatics
            </div>
          </div>
          <a href="/demo" className="button-blue-border bigbutton w-button"
            >Request a Demo</a
          >
        </div>
        <img
          src={img2}
          loading="lazy"
          width="500"
          height="500"
          alt=""
          className="vectors-wrapper-2"
        />
      </div>
    </div>
  )
}

export default HeroHeader;