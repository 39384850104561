import React from 'react'
import ComponentIndex from './components/navbar'
import HeroHeader from './components/heroHeader'
import Testimonial from './components/testimonial'
import TestimonialSection from './components/TestimonialSection'
import TeamCircles from './components/TeamCircles'
import DemoForm from './components/DemoForm'
import FooterIndex from './components/footer'
import MyComponent from './components/MyComponent'
import VideoSection from './components/VideoSection'


function MainIndex() {
  return (
      <div>
      <ComponentIndex />
      <HeroHeader />
      <Testimonial />
      <MyComponent />
      <VideoSection/>
      <TestimonialSection />
      <TeamCircles />
      <DemoForm/>
      <FooterIndex />
    </div>
  )
}

export default MainIndex