import React from 'react';

function VideoSection() {
  return (
    <section className="section">
      <div className="lefttextcontainer w-container">
        <div className="w-embed w-iframe w-script">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src="../../images/biox.mp4"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
              title="bioXverse_-_brand_launch (1080p)"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;