import React from 'react'
import "./index.css"
import ComponentIndex from '../navbar'
import FooterIndex from '../footer'
function ContactUsIndex() {
  return (
    <>
	<ComponentIndex/>
    {/* <div class="contact-form">
		<div class="form-container">
			<h2>Your Details</h2>
			<form action="#" method="POST">

				<label for="name">Name: </label>
				<input type="text" id="name" name="name" required/>

				<label for="email">Email Address: </label>
				<input type="email" id="email" name="email" required/>

				<label for="phone">Phone Number: </label>
				<input type="tel" id="phone" name="phone"/>

				<label for="Address">Address: </label>
				<textarea id="message" name="message" rows="4" required></textarea>

				<button type="submit" class="submit-button">Submit</button>
			</form>
		</div> */}
    
    <div class="contact-info">
		<h2>Contact Information</h2>
		<address>
			BioXVerse<br/>
			P-40/2, 2nd Floor, Ananthapura St, Yelahanka, Bengaluru, Karnataka 560064<br/>
			Email: <a className='text-white text-decoration-none' href="mailto:services@bioxverse.com">services@bioxverse.com</a>
		</address>
          </div>  
		  <FooterIndex/>
    </>      
       
    
                 
  )
}

export default ContactUsIndex